import { Routes, Route } from 'react-router-dom';

import List from './pages/List';
import Detail from './pages/Detail';
import Header from './components/Header';

import './App.css';

function Router() {
    return (
        <div>
            <Header />
            <Routes>
                <Route path="/list" element={<List />} />
                <Route path="/detail/:id" element={<Detail />} />
                <Route index element={<List />} />
            </Routes>
        </div>
    );
}

export default Router;
