import { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import styled, { css } from "styled-components";

function Pagenation(props) {
    const [pagenation, setPagenation] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams({ s: '', f: '', p: 1 });
    const s = searchParams.get('s');
    const f = searchParams.get('f');
    const p = searchParams.get('p');

    const EXPOSURE_ARTICLE_NUMBER = 20;
    const EXPOSURE_SLIDE_NUMBER = 5;

    const offset = Number(p);
    const lastIndex = Math.ceil(props.article_count / EXPOSURE_ARTICLE_NUMBER);

    useEffect(() => {
        if (lastIndex < 5) {
            setPagenation([...Array(lastIndex).keys()].map(key => key + 1));
        } else {
            if (offset < 3) {
                setPagenation([1, 2, 3, 4, 5]);
            } else if (offset > lastIndex - 3) {
                setPagenation([lastIndex - 4, lastIndex - 3, lastIndex - 2, lastIndex - 1, lastIndex]);
            } else {
                setPagenation([offset - 2, offset - 1, offset, offset + 1, offset + 2]);
            }
        }
    }, [searchParams, props, offset, lastIndex]);

    const change_page = (e) => {
        setSearchParams({ s, f, p: e.target.dataset.id });
    }

    const go_right = () => {
        if (pagenation.length < 5) {
            return
        } else if (pagenation.at(-1) + EXPOSURE_SLIDE_NUMBER > lastIndex) {
            setPagenation([lastIndex - 4, lastIndex - 3, lastIndex - 2, lastIndex - 1, lastIndex]);
        } else {
            setPagenation(pagenation.map((data) => {
                return data + pagenation.length;
            }));
        }
    }

    const go_left = () => {
        if (pagenation.length < 5) {
            return
        } else if (pagenation[0] - EXPOSURE_SLIDE_NUMBER < 0) {
            setPagenation([1, 2, 3, 4, 5]);
        } else {
            setPagenation(pagenation.map((data) => {
                return data - pagenation.length;
            }));
        }
    }

    return (
        <PageNavigation isNeed={pagenation.length <= 1 ? false : true}>
            <NavButton onClick={go_left}>{"<"}</NavButton>
            {
                pagenation.map((data, idx) => (
                    <NavButton key={idx} onClick={change_page} data-id={data} selected={data === Number(p)}>{data}</NavButton>
                ))
            }
            <NavButton onClick={go_right}>{">"}</NavButton>
        </PageNavigation>
    );
}

export default Pagenation;

const PageNavigation = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 36px;

    margin: auto;

    font-size: 18px;

    ${props =>
        !props.isNeed &&
        css`
            display: none;
    `};
`;

const NavButton = styled.p`
    cursor: pointer;

    color: ${({ selected }) => {
        if (selected) {
            return "#323232";
        }
        return "#707070"
    }};
`;