import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styled, { css } from "styled-components";

function TOC(props) {
  const location = useLocation();

  const [indexList, setIndexList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState("");

  useEffect(() => {
    const hNodeList = document.querySelector(".ck-content")?.querySelectorAll("h2, h3, h4");

    const IOList = [];
    let IO;

    [...hNodeList].forEach((node) => {
      const index = node.textContent;
      const size = (+node.nodeName[1] - 1) * 20;
      setIndexList((prev) => {
        if (prev.map((v) => v.index).includes(index)) return prev;
        return [...prev, { index, size }];
      });

      node.id = index;
      node.className = "anchor";

      IO = new IntersectionObserver(([{ isIntersecting, target: { textContent } },]) => {
          if (!isIntersecting) return;
          setCurrentIndex(textContent);
        },
        { rootMargin: '0px -165px 0px 0px', threshold: 1 }
      );
      IO.observe(node);

      IOList.push(IO);
    });

    return () => IOList.forEach((IO) => IO.disconnect());
  }, [document.querySelector(".ck-content")]);

  return (
    <TocUl isNeed={indexList.length === 0 ? false : true}>
        <Title>{indexList.length === 0 ? "" : props.title}</Title>
        {indexList.map(({ index, size }) => (
            <TocList
                key={index}
                style={{
                    paddingLeft: size + "px"
                }}
                isExposed={currentIndex === index}>
                <a href={`${location.pathname}#${index}`}>{index}</a>
            </TocList>
        ))}
    </TocUl>
  );
};

export default TOC;

const TocUl = styled.ul`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 8px;

    font-size: 18px;

    ${props =>
        !props.isNeed &&
        css`
            display: none;
    `};
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const TocList = styled.li`
    & a {
        color: #707070;
    }

    ${props =>
        props.isExposed &&
        css`
            border-left: 2px solid #323232;
            & a {
                color: #323232;
            }
    `};
`;