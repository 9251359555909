import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle` // [p1] 전역에서 사용할 css 작성
  @font-face {
    font-family: 'gmarket';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    }

    @font-face {
        font-family: 'nanum';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NanumSquareRound.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'nanumExtraBold';
        src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareEB.eot);
        src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareEB.eot?#iefix) format("embedded-opentype"), url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareEB.woff) format("woff"), url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareEB.ttf) format("truetype");
    }

    html {
        scroll-behavior: smooth;
    }

    a {
        text-decoration: none;
        color: inherit
    }

    h1, h2, h3, h4, h5, h6, p, a, button, input {
        font-family: nanum;
        color: #323232;
    }
`;

export default Global;