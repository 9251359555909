import React, { useEffect, useState } from 'react';
// import { createRoot } from "react-dom/client";
import { Helmet } from 'react-helmet-async';

import styled from 'styled-components';
import { useParams, useLocation } from 'react-router-dom';

import TOC from '../components/TOC';
import GoogleAdvertiseDisplay from "../components/GoogleAdvertiseDisplay";
// import GoogleAdvertiseContent from '../components/GoogleAdvertiseContent';


function Detail() {
    const [data, setData] = useState({});
    const location = useLocation();
    const params = useParams();

    useEffect(() => {
        fetch('https://api.kerbo.co.kr/article/' + params.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                if (res.ok) {
                    res.json().then((data) => {
                        setData(data);
                    });
                }
            });
    }, [params.id]);

    // useEffect(() => {
    //     const parents = document.querySelector(".ck-content");
    //     const nodeList = parents?.getElementsByTagName('*');

    //     console.log(nodeList);

    //     if (nodeList.length) {
    //         let height_counter = 0;
    //         for (let key in nodeList) {
    //             if (height_counter > 2500) {
    //                 height_counter = 0;
    //                 var newNode = document.createElement("p");
    //                 newNode.textContent = "ad";

    //                 parents.insertBefore(
    //                     newNode,
    //                     nodeList[key]
    //                 );
    //             }

    //             let height = nodeList[key].offsetHeight;

    //             if (height) {
    //                 height_counter += height;
    //             }

    //             // console.log(key, height_counter, height);
    //         }

    //         // const root = createRoot(document.getElementById("ad"));

    //         // let reactNode = React.createElement(GoogleAdvertiseContent, {
    //         //     client: "ca-pub-8175734689832074",
    //         //     slot: "6800904962",
    //         //     format: "fluid",
    //         //     layout: "in-article"
    //         // });

    //         // root.render(reactNode);
    //     }
    //   }, [data]);

    return (
        <Article className='article'>
            <Helmet>
                <title>{data['TITLE']}</title>
                <meta property="og:site_name" content="Kerbo" />
                <meta property="og:title" content={data['TITLE']} />
                <meta property="og:url" content={"https://kerbo.co.kr" + location.pathname} />
                <meta property="og:description" content={data['CONTENT']} />
                <meta property="og:image" content={data['COVER_IMAGE']} />
            </Helmet>
            <CoverImage src={data['COVER_IMAGE']} alt="" />
            <InnerWapper>
                <Title>{data['TITLE']}</Title>
                <ContentWrapper>
                    <ContentBox dangerouslySetInnerHTML={{__html : data['CONTENT'] }} className="ck-content"></ContentBox>
                    <SideBox>
                        <StickyBox>
                            <TOC title={data['TITLE']}/>
                            <GoogleAdvertiseDisplay
                                client="ca-pub-8175734689832074"
                                slot="1055955392"
                                format="auto"
                                responsive="true"
                                />
                        </StickyBox>
                    </SideBox>
                </ContentWrapper>
            </InnerWapper>
        </Article>
    );
}

export default Detail;

const Article = styled.article`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 42px;
`;

const CoverImage = styled.img`
    width: 100%;
    height: 392px;
    object-fit: cover;
`;

const InnerWapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 40px;

    margin: 0px 160px;

    @media screen and (max-width: 1594px) {
        margin: auto;
        width: 1274px;
    }

    @media screen and (max-width: 1370px) {
        width: auto;
        margin: 0px 40px;
    }

    @media screen and (max-width: 767px) {
        margin: 0px 20px;
        margin-bottom: 50px;
        
        gap: 24px;
    }
`;

const Title = styled.h1`
    font-family: nanumExtraBold;
    font-size: 36px;
    line-height: 52px;

    @media screen and (max-width: 767px) {
        font-size: 28px;
        line-height: 38px;
    }
`;

const ContentWrapper = styled.section`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 60px;
`;

const ContentBox = styled.section`
    width: 70%;

    margin-bottom: 100px;

    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;

const SideBox = styled.section`
    width: calc(30% - 60px);

    @media screen and (max-width: 767px) {
        display: none;
    }
`;

const StickyBox = styled.div`
    height: calc(100vh - 165px);
    overflow-y: scroll;

    position: sticky;
    top: 166px;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 60px;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;