import { useEffect, useState } from 'react';
import { Link, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

function Header(props) {
    const [navList, setNavList] = useState([]);
    const [showMobileNav, setShowMobileNav] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams({ s: '', f: '' });
    const s = searchParams.get('s');
    const f = searchParams.get('f');

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        fetch('https://api.kerbo.co.kr/categories', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            if (res.ok) {
                res.json().then((data) => {
                    setNavList(data);
                });
            }
        });
    }, [props.type]);

    const setQueryFilter = (e) => {
        if (location.pathname === "/" || location.pathname === "/list") {
            setSearchParams({ s, f: e.target.dataset.id });
        } else {
            navigate('/?f=' + e.target.dataset.id);
        }
    };

    const setQuerySearch = (e) => {
        if (e.key === 'Enter') {
            e.target.blur();

            if (location.pathname === "/" || location.pathname === "/list") {
                setSearchParams({ s: e.target.value, f });
            } else {
                navigate('/?s=' + e.target.value);
            }
        }
    };

    const showNav = (e) => {
        setShowMobileNav(!showMobileNav);
    }

    return (
        <HeaderBox type={props.type}>
            <HeaderContent>
                <InnerWapper>
                    <Link to="/">
                        <Logo>KERBO</Logo>
                    </Link>
                    <Nav>
                        {navList.map((data, idx) => (
                            <NavP key={data['CATEGORY_ID']} data-id={data['CATEGORY_ID']} onClick={setQueryFilter} isActivate={String(data['CATEGORY_ID']) === f}>
                                {data['CATEGORY_NAME']}
                            </NavP>
                        ))}
                    </Nav>
                </InnerWapper>
                <SearchBar type="text" placeholder="Search" onKeyPress={setQuerySearch} defaultValue={s}></SearchBar>
                <Hamburger src="/sys_image/hamburger_icon.png" onClick={showNav}></Hamburger>
                <NavBox showMobileNav={showMobileNav}>
                    <HamburgerCloser src="/sys_image/hamburgerCloser_icon.png" onClick={showNav}></HamburgerCloser>
                    <SearchBar type="text" placeholder="Search" onKeyPress={setQuerySearch} isMobile={true} defaultValue={s}></SearchBar>
                    <Nav isMobile={true}>
                        <NavP data-id={""} onMouseDown={setQueryFilter} isActivate={"" === f}>모두</NavP>
                        {navList.map((data, idx) => (
                            <NavP key={data['CATEGORY_ID']} data-id={data['CATEGORY_ID']} onClick={setQueryFilter} isActivate={String(data['CATEGORY_ID']) === f}>
                                {data['CATEGORY_NAME']}
                            </NavP>
                        ))}
                    </Nav>
                </NavBox>
            </HeaderContent>
        </HeaderBox>
    );
}

export default Header;

const HeaderBox = styled.header`
    position: sticky;
    top: 0;
    z-index: 10;

    height: 165px;
    background-color: white;

    padding-top: env(safe-area-inset-top);

    @media screen and (max-width: 767px) {
        height: 80px;
    }
`;

const HeaderContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    margin: 0px 160px;
    box-sizing: border-box;

    @media screen and (max-width: 1594px) {
        margin: auto;
        width: 1274px;
    }

    @media screen and (max-width: 1370px) {
        width: auto;
        margin: 0px 40px;
    }

    @media screen and (max-width: 767px) {
        height: 100%;

        margin: 0px 20px;
        margin-bottom: 50px;

        align-items: center;
    }
`;

const InnerWapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 25px;

    margin-top: 30px;
    @media screen and (max-width: 767px) {
        margin-top: 6px;
    }
`;

const Logo = styled.h1`
    font-size: 46px;
    font-family: gmarket;

    @media screen and (max-width: 767px) {
        font-size: 28px;
    }
`;

const Nav = styled.nav`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 18px;

    white-space: nowrap;

    font-size: 18px;
    color: #707070;

    & p {
        cursor: pointer;
    }

    @media screen and (max-width: 767px) {
        ${props =>
            !props.isMobile &&
            css`
                display: none;
        `};

        ${props =>
            props.isMobile &&
            css`
                display: flex;
                flex-direction: column;
                width: 100%;
        `};
    }
`;

const NavP =styled.p`
    ${props =>
        props.isActivate &&
        css`
            font-weight: bold;
    `};
`;

const SearchBar = styled.input`
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 20px;
    background-color: #f2f2f2;
    outline: none;

    flex-shrink: 0;

    margin-top: 43px;
    padding-left: 16px;

    box-sizing: border-box;

    @media screen and (max-width: 767px) {
        ${props =>
            !props.isMobile &&
            css`
                display: none;
        `};

        ${props =>
            props.isMobile &&
            css`
                display: block;
                width: 100%;
                height: 40px;
                font-size: 18px;
                margin-top: 40px;
        `};
    }
`;

const Hamburger = styled.img`
    display: none;

    width: 36px;
    height: 36px;

    @media screen and (max-width: 767px) {
        display: block;
    }
`;

const HamburgerCloser = styled.img`
    width: 42px;
    height: 42px;
`;

const NavBox = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 70vw;
    height: 100vh;
    background-color: white;

    flex-direction: column;
    gap: 40px;
    
    padding: 50px 40px 20px;

    box-sizing: border-box;

    display: none;

    @media screen and (min-width: 767px) {
        display: none;
    }

    ${props =>
            props.showMobileNav &&
            css`
                display: flex;
        `};
`;