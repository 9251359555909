import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import styled from 'styled-components';
import Pagenation from '../components/Pagenation';

function List() {
    const [list, setList] = useState([]);
    const [articleCount, setArticleCount] = useState(0);

    const [searchParams, setSearchParams] = useSearchParams({ s: '', f: '', p: 1 });
    const s = searchParams.get('s');
    const f = searchParams.get('f');
    const p = searchParams.get('p');

    const EXPOSURE_ARTICLE_NUMBER = 20;

    useEffect(() => {
        let start_index = !p ? 0 * EXPOSURE_ARTICLE_NUMBER: (p - 1) * EXPOSURE_ARTICLE_NUMBER;

        fetch('https://api.kerbo.co.kr/list?s=' + s + '&f=' + f + '&p=' + start_index, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                if (res.ok) {
                    res.json().then((data) => {
                        setArticleCount(parseInt(data['ARTICLE_COUNT']));
                        setList(data['SEARCH_RESULT']);
                    });
                }
            });
    }, [f, s, p]);

    return (
        <Cardlist>
            <Helmet>
                <title>Kerbo</title>
                <meta property="og:site_name" content="Kerbo" />
                <meta property="og:title" content="Kerbo" />
                <meta property="og:url" content="https://kerbo.co.kr" />
                <meta property="og:description" content="Kerbo의 블로그" />
            </Helmet>
            <CardlistWapper>
                {list.map((data, idx) => (
                    <Link key={data['ARTICLE_ID']} to={'/detail/' + data['ARTICLE_ID']}>
                        <CardBox>
                            <ImageSection>
                                <CoverImage src={data['COVER_IMAGE']} />
                            </ImageSection>
                            <TextSection>
                                <Title>{data['TITLE']}</Title>
                                <SampleText>{data['CONTENT']}</SampleText>
                            </TextSection>
                        </CardBox>
                    </Link>
                ))}
            </CardlistWapper>
            <Pagenation article_count={articleCount} />
        </Cardlist>
    );
}

export default List;

// nomal
const Cardlist = styled.section`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 60px;

    margin: 0px 160px;
    margin-bottom: 100px;

    box-sizing: border-box;

    @media screen and (max-width: 1594px) {
        margin: auto;
        width: 1274px;
    }

    @media screen and (max-width: 1370px) {
        width: auto;
        margin: 0px 40px;
        margin-bottom: 100px;
    }

    @media screen and (max-width: 767px) {
        margin: 0px 20px;
        margin-bottom: 50px;
    }
`;

const CardlistWapper = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 25px 25px;

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, minmax(300px, 1fr));
    }
`;

const CardBox = styled.article`
    position: relative;
    width: 100%;
    height: 350px;
    background-color: #f2f2f2;

    border-radius: 10px;
    overflow: hidden;
`;

const ImageSection = styled.section`
    width: 100%;
    height: 180px;
`;

const CoverImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const TextSection = styled.section`
    padding: 18px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 12px;
`;

const Title = styled.h2`
    font-size: 20px;
    line-height: 26px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const SampleText = styled.p`
    font-size: 16px;
    line-height: 22px;

    height: 68px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;
